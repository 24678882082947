import UserService from '@/services/user.service'

export let items

const profile = UserService.getLocalProfile()
if (profile) {
  if (profile.role === 'Client User') {
    items = [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        to: '/dashboard'
      },
      {
        title: 'Pentests',
        icon: 'mdi-rocket-launch-outline',
        to: '/pentests'
      },
      {
        title: 'Assets',
        icon: 'mdi-package-variant',
        to: '/assets'
      },
      {
        title: 'Documents',
        icon: 'mdi-folder-outline',
        to: '/documents'
      }
    ]
  } else if (profile.role === 'Client Admin') {
    items = [
      {
        title: 'Dashboards',
        icon: 'mdi-view-dashboard',
        to: '/dashboard'
      },
      {
        title: 'Pentests',
        icon: 'mdi-rocket-launch-outline',
        to: '/pentests'
      },
      {
        title: 'Assets',
        icon: 'mdi-package-variant',
        to: '/assets'
      },
      {
        title: 'Documents',
        icon: 'mdi-folder-outline',
        to: '/documents'
      },
      {
        title: 'Users',
        icon: 'mdi-account-box-outline',
        to: '/users'
      },
      {
        title: 'Logs',
        icon: 'mdi-history',
        to: '/logs'
      }
    ]
  } else if (profile.role === 'Partner') {
    items = [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        to: '/partnership'
      },
      {
        title: 'Quote',
        icon: 'mdi-file-document-outline',
        to: '/quote'
      },
      {
        title: 'Clients',
        icon: 'mdi-domain',
        to: '/clients'
      },
      {
        title: 'Logs',
        icon: 'mdi-history',
        to: '/logs'
      }
    ]
  } else if (profile.role === 'Superuser') {
    items = [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        to: '/dashboard'
      },
      {
        title: 'Pentests',
        icon: 'mdi-rocket-launch-outline',
        to: '/pentests'
      },
      {
        title: 'Assets',
        icon: 'mdi-package-variant',
        to: '/assets'
      },
      {
        title: 'Documents',
        icon: 'mdi-folder-outline',
        to: '/documents'
      },
      {
        title: 'Users',
        icon: 'mdi-account-box-outline',
        to: '/users'
      },
      {
        title: 'Clients',
        icon: 'mdi-domain',
        to: '/clients'
      },
      {
        title: 'Logs',
        icon: 'mdi-history',
        to: '/logs'
      },
      {
        title: 'Templates',
        icon: 'mdi-layers-outline',
        to: '/templates'
      }

      // {
      //   title: 'Partnership',
      //   icon: 'mdi-account-tie',
      //   to: '/partnership',
      // },
      // {
      //   title: 'Quote',
      //   icon: 'mdi-file-document-outline',
      //   to: '/quote',
      // },
    ]
  }
}
